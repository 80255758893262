<template>
  <div>
    <optionscard :deliverParentNum="5"></optionscard>
    <breadcrumb></breadcrumb>
    <div class="article-top">
      <div class="width1200"></div>
      <!-- 文章（左边） -->
      <!-- 文章标题 -->
      <div class="article-title">{{ earthquakeList.title }}</div>
      <!-- 文章时间 -->
      <div class="article-data">{{ earthquakeList.issue_time }}</div>
    </div>
    <!-- 文章内容 -->
    <div class="article">
      <div class="article-left">
        <div v-html="earthquakeList.content"></div>
      </div>
      <!-- 热门知识（右边） -->
      <div class="hot-knowledge-div">
        <div class="hotKnowledgeTop">
          <div class="bluebulk"></div>
          <div class="hot-knowledge">热门知识</div>
        </div>
        <!-- 热门知识推荐 -->
        <div
          class="hot-article-div2"
          v-for="item in hotKnowledgeList"
          :key="item.id"
          @click="getHotKnowledgeArticle(item.id)"
        >
          <!-- 热门知识推荐文章标题 -->
          <div class="hot-article-title">{{ item.title }}</div>
          <!-- 热门知识推荐文章内容 -->
          <div class="hot-article-body" v-html="item.content"></div>
          <!-- 热门知识推荐文章时间 -->
          <div class="hot-article-data">{{ item.issue_time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    return {
      // 存放地震知识文章数据
      earthquakeList: [],
      //
      earthquakeListSecond: [],
      // 地震知识文章参数
      earthquake: {},
      // 热门知识数据
      hotKnowledgeList: []
    }
  },
  created () {
    console.log('id', this.$route.query.id)
    const id = this.$route.query.id
    this.getHotKnowledge(id)
    this.earthquakeArticle(id)
  },
  methods: {
    // 获取地震知识文章数据
    async earthquakeArticle (id) {
      const { res } = await this.$api.earthquakeknowledge.getEarthquakeArticle({
        id
      })
      console.log('res', res)
      // 去除时间后面的时分秒
      if (res.issue_time.length > 10) {
        res.issue_time = res.issue_time.slice(0, 10)
      }
      this.earthquakeList = res
      console.log('2', this.earthquakeList)
    },
    // 获取热门知识列表
    async getHotKnowledge (id) {
      const { res } = await this.$api.earthquakeknowledge.getHotKnowledgeList({
        pid: 2
      })
      res.forEach((i, index, array) => {
        if (i.id === Number(id)) {
          res.splice(index, 1)
        }
      })
      for (const i of res) {
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
        // 标题字太多给... 只显示一行
        // if (i.title.length > 13) {
        //   i.title = i.title.slice(0, 13) + '...'
        // }
        //  删去内容里的html标签
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
        // 内容字太多给... 只显示一行
        // if (i.content.length > 15) {
        //   i.content = i.content.slice(0, 15) + '...'
        // }
      }
      console.log('res1', res)
      this.hotKnowledgeList = res
    },
    // 点击热门知识跳转
    async getHotKnowledgeArticle (id) {
      this.$router.push({ path: 'dzzssecond', query: { id } })
    }
  },
  watch: {
    '$route.query.id' () {
      // 监听路由变化  // 组件第一次进入不会执行
      console.log('id 变化了', this.$route.query.id)
      this.earthquakeArticle(this.$route.query.id)
      this.getHotKnowledge(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.article-top {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 10px;
}

.article-title {
  width: 1200px;
  margin: 0 auto;
  font-size: 28px;
  margin-top: 36px;
}

.article-data {
  text-align: center;
  height: 25px;
  line-height: 25px;
  margin: 18px 1440px 30px 360px;
  font-size: 14px;
  color: #999999;
  background-color: #f7f7f7;
}

.article {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top: 30px;
}

.article-left {
  width: 795px;
  font-size: 15px;
  color: #606060;
  float: left;
  margin-bottom: 85px;
}

.hot-knowledge-div {
  width: 315px;
  margin-top: 20px;
  margin-bottom: 85px;
  float: right;
  border: solid 1px #eaeaea;
}

.hotKnowledgeTop {
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
}

.article-picture {
  width: 580px;
  height: 362px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.bluebulk {
  width: 4px;
  height: 24px;
  background-color: #2e88e4;
  display: inline-block;
  margin-top: 5px;
}

.hot-knowledge {
  height: 66px;
  font-size: 24px;
  color: #333333;
  margin-left: 10px;
}

.hot-article-div2 {
  margin: 0 18px;
  padding-top: 40px;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  margin-bottom: 29px;
}

.hot-article-div2:hover .hot-article-title {
  color: #2e88e4;
  cursor: pointer;
}

.hot-article-title {
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-article-body {
  font-size: 16px;
  margin-bottom: 19px;
  color: #777777;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hot-article-data {
  background-color: #f7f7f7;
  text-align: center;
  margin-right: 170px;
  font-size: 14px;
  color: #999999;
}
</style>
